import React, { ReactElement } from "react"
import { Layout } from "./../components/Layout"
import { graphql } from "gatsby"
import GatsbyImage from "gatsby-image"
import { IndexPageQuery } from "../../graphql-types"
import { PageWithQueryProps } from "../helper-types"

const Emphasize: React.FunctionComponent = ({ children }) => (
  <span className="text-primary-600 font-bold">{children}</span>
)
function AboutMe({ data }: PageWithQueryProps<any>): ReactElement {
  return (
    <Layout>
      <main>
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">Passion for </span>
              <span className="block text-primary-600 xl:inline">the web</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              I enjoy working with <Emphasize>modern web technology</Emphasize>
              <span className="text-primary-600 font-bold">, </span>
              while finding the sweet spot between{" "}
              <Emphasize>pragmatism</Emphasize> and{" "}
              <Emphasize>perfectionism</Emphasize>.
              <br />
              <p className="mt-3">
                As long as there's no global pandemic, you find me on{" "}
                <Emphasize>stages</Emphasize> at various tech{" "}
                <Emphasize>conferences</Emphasize> all over the world.
              </p>
              <p className="mt-6">
                I love to be able to share my knowledge in my day job as a
                consultant with{" "}
                <a href="https://suora.com" className="text-brand-suora-900">
                  Suora
                </a>
                .
              </p>
              <p>You can hire me over there.</p>
              <p className="mt-6">
                You can also drop me a{" "}
                <a className="text-indigo-500" href="mailto:c.otto@lab9.de">
                  mail
                </a>{" "}
                or get in touch via twitter - I'm known as{" "}
                <a
                  href="https://twitter.com/muhdiekuh"
                  className="text-indigo-500"
                >
                  @muhdiekuh
                </a>{" "}
                there.
              </p>
            </p>
            <p className="text-xs lg:absolute lg:bottom-1">
              PS: As you can see on this page, design is not my strength.
            </p>
          </div>
        </div>
        <div className="relative w-full h-64 sm:h-screen md:h-screen lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          {data.file?.childImageSharp?.fluid && (
            <GatsbyImage
              className="rounded absolute inset-0 w-full h-full object-cover object-top md:rounded-none"
              fluid={data.file.childImageSharp.fluid}
              alt="Picture of Christian"
            />
          )}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query IndexPage {
    file(
      name: { eq: "suora-christian" }
      dir: {}
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutMe
